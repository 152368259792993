<template>
  <vs-row
    :style="{ backgroundImage: 'url(' + background + ')', height: '368px' }"
    class="rounded-lg overflow-hidden bg-no-repeat bg-center bg-cover"
    vs-type="flex"
    vs-justify="center"
    vs-align="center"
  >
    <vs-col vs-w="12" class="text-center">
      <vs-icon
        icon-pack="feather"
        icon="icon-alert-triangle"
        color="warning"
        size="50px"
      />
      <h1 class="pt-2 text-quartz">Você está tentando acessar</h1>
      <h1 class="pt-2 text-quartz">
        <strong>fora do horário de atendimento</strong>
      </h1>
      <h5 class="pt-5 text-quartz">Por favor, entre em contato com seu</h5>
      <h5 class="pt-1 text-quartz">
        <strong>administrador</strong> para mais informações
      </h5>
    </vs-col>
  </vs-row>
</template>
<script>
import background from "@/assets/images/background-block.png";
export default {
  name: "Restricted",
  data() {
    return {
      background: background,
    };
  },
  mounted() {
    this.$vs.loading.close();
  },
};
</script>
